<template>
    <div class="exposure-rating">
        <Head ref="head"></Head>
        <div class="dw_body_content">
            <div class="dwSurveyHeader">
                {{ title }}
            </div>
            <div class="dwSurveyQuContent">
                <!-- 标题 -->
                <div
                        v-for="(item, index) in pullList"
                        :key="index"
                        class="surveyQuItemContent"
                >
                    <div v-if="item.type != 1">
                        {{ index + 1 + "、" + item.title }}
                    </div>
                    <div v-else>
            <span>
            {{ index + 1 + "、" }}
            </span>
                        <span v-for="(el, index) in item.typeList">
              <span v-if="el.type == 2">
                {{ el.content }}
              </span>
              <el-input
                      type="text"
                      size="small"
                      style="width:150px"
                      class="under-input-new"
                      v-else-if="el.type == 1"
                      v-model.trim="item.answer[el.index]"
              ></el-input>
            </span>
                    </div>
                    <div style="margin: 19px 6px;">
                        <!-- 填空 -->
                        <el-input
                                v-if="item.type == 4"
                                type="textarea"
                                autosize=""
                                v-model.trim="item.answer"
                                placeholder=""
                        ></el-input>
                        <!-- 单选 -->
                        <el-radio-group v-model="item.answer" v-else-if="item.type == 2" @change="changeRadio($event,index,item.options)">
                            <el-radio :label="el.label" v-for="(el,i) in item.options">
                                {{ el.label }}
                                <el-input
                                        v-if="el.type == 1"
                                        style="width:300px"
                                        v-model.trim="item.otherValue"
                                        placeholder=""
                                        size="mini"
                                        :id="index+i"
                                        disabled="disabled"
                                ></el-input>
                            </el-radio>
                        </el-radio-group>
                        <!-- 多选 -->
                        <el-checkbox-group
                                v-model="item.multanswer"
                                v-else-if="item.type == 3"
                        >
                            <el-checkbox
                                    :label="el.label"
                                    style="display:block;"
                                    v-for="(el,i) in item.options"
                                    @change="changeCheckbox($event,index,item.options,i)"
                            >
                                <div v-if="el.type == 1">
                                    {{ el.label }}
                                    <el-input
                                            style="width:300px"
                                            v-model.trim="item.otherValue"
                                            placeholder=""
                                            size="mini"
                                            :id="index+i"
                                            disabled="disabled"
                                    ></el-input>
                                </div>
                            </el-checkbox>
                        </el-checkbox-group>
                        <!-- 判断 -->
                        <el-radio-group v-model="item.answer" v-else-if="item.type == 5">
                            <el-radio :label="el.label" v-for="el in item.options">
                                {{ el.label }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="action-area">
                <el-button type="primary" @click="confirmForm" v-if="!isSubmit"
                >提交
                </el-button
                >
                <el-button type="primary" disabled v-else>已提交</el-button>
            </div>
        </div>
        <!-- 提交  end -->
    </div>
</template>

<script>
    import {get, post} from "../utils/request";
    import Head from "../views/components/HeadNoMenu.vue";

    export default {
        components: {
            Head,
        },
        data() {
            return {
                isSubmit: false,
                // 注册登录
                dialogVisible: false,
                username: "",
                password: "",
                user: "",
                questionnaireType: "",
                innerVisible: false,
                ruleForm: {
                    user: "",
                    password: "",
                    enterpriseName: "",
                },
                rules: {
                    user: [{required: true, message: "请输入账号", trigger: "blur"}],
                    password: [{required: true, message: "请输入密码", trigger: "blur"}],
                    enterpriseName: [
                        {required: true, message: "请输入企业名字", trigger: "blur"},
                    ],
                },

                pullList: [
                    // //以下是服务端返回的试题列表数据
                    // {
                    //   title: "填空题题目",
                    //   answer: "问卷答案",
                    //   type: 1,
                    // },
                    // {
                    //   title: "单选题题目",
                    //   options: [
                    //     {
                    //       value: "选项1",
                    //       type: 1,
                    //     },
                    //     {
                    //       value: "选项2",
                    //       type: 1,
                    //     },
                    //     {
                    //       value: "其他",
                    //       type: 2,
                    //     },
                    //   ],
                    //   answer: "问卷答案",
                    //   type: 2,
                    // },
                    // {
                    //   title: "多选题答案",
                    //   options: [
                    //     {
                    //       value: "选项1",
                    //       type: 1,
                    //     },
                    //     {
                    //       value: "选项2",
                    //       type: 1,
                    //     },
                    //     {
                    //       value: "其他",
                    //       type: 2,
                    //     },
                    //   ],
                    //   answer: "问卷答案",
                    //   multanswer: [],
                    //   type: 3,
                    // },
                ],
                title: "",
            };
        },
        mounted() {
            this.user = localStorage.user || null;
            this.questionnaireType = localStorage.questionnaireType || null;
            this.getQuestionnaireQuestionss(); //获取试题列表
        },
        methods: {
            changeCheckbox(valBoolean,index,option,optionIndex){
                if (valBoolean){
                    if (option[optionIndex]['type'] == 1) {
                        var checkboxId = index + optionIndex;
                        $("#"+checkboxId+"").removeAttr("disabled");
                        $("#"+checkboxId+"").parent().removeClass("is-disabled");
                    }
                }else {
                    if (option[optionIndex]['type'] == 1) {
                        var checkboxId = index + optionIndex;
                        $("#"+checkboxId+"").attr("disabled","disabled");
                        $("#"+checkboxId+"").parent().addClass("is-disabled");
                    }
                }
            },
            changeRadio(val,index,option){
                var optionIndex = option.length - 1;
                var lastVal = val;
                if (lastVal == option[optionIndex]['label'] && option[optionIndex]['type'] == 1) {
                    var radioId = index + optionIndex;
                    $("#"+radioId+"").removeAttr("disabled");
                    $("#"+radioId+"").parent().removeClass("is-disabled");
                }else {
                    var radioId = index + optionIndex;
                    $("#"+radioId+"").attr("disabled","disabled");
                    $("#"+radioId+"").parent().addClass("is-disabled");
                }
            },
            confirmForm() {
                if (!localStorage.token) {
                    this.$message({
                        message: "请先登录",
                        type: "warning",
                    });
                    setTimeout(() => {
                        this.dialogVisible = true;
                    }, 1300);
                }
                // 必填校验
                this.pullList.map((el, index) => {
                    //题目为必填
                    if (el.requiredStatus == 1){
                        // 填空题
                        if (el.type == 1) {
                            let checkFlag = true;
                            el.answer.map((ele) => {
                                if (ele === "" || ele.length == 0) {
                                    checkFlag = false;
                                }
                            });
                            if (!checkFlag) {
                                this.$message({
                                    message: `请先完成第${index + 1}题`,
                                    type: "warning",
                                });
                                retrun;
                            }
                        }
                        //单选题、简答题、对错题
                        if ((el.type == 2 || el.type == 4 || el.type == 5) && el.answer == "") {
                            this.$message({
                                message: `请先完成第${index + 1}题`,
                                type: "warning",
                            });
                            retrun;
                        }
                        //多选题
                        if (el.type == 3 && el.multanswer.length == 0) {
                            this.$message({
                                message: `请先完成第${index + 1}题`,
                                type: "warning",
                            });
                            retrun;
                        }
                        if (el.type == 3 && el.multanswer.length != 0 && el.otherValue == "") {
                            var multanswer = el.multanswer;
                            var options = el.options;
                            for (let i = 0; i < options.length; i++) {
                                var label = options[i].label;
                                var type = options[i].type;
                                var indexLabel = $.inArray(label, multanswer);
                                if (type == 1 && indexLabel != -1 && el.otherValue == ""){
                                    this.$message({
                                        message: `请先完成第${index + 1}题的` + multanswer[indexLabel],
                                        type: "warning",
                                    });
                                    retrun;
                                }
                            }
                        }
                    }
                });
                let answerObj = JSON.parse(JSON.stringify(this.pullList));
                answerObj.map((el) => {
                    if (el.type == 2 && el.answer === this.getOtherText(el.options)) {
                        el.answer = el.otherValue || el.answer;
                    }
                    if (el.type == 3) {
                        let text = this.getOtherText(el.options);
                        el.multanswer.map((item, index) => {
                            if (item == text) {
                                el.multanswer[index] = el.otherValue || text;
                            }
                        });
                        el.answer = el.multanswer.join(",");
                    }
                    //多选题重新排序
                    if (el.type == 3){
                        var multanswerTemp = this.sortcheckedCities(el.options, el.multanswer);
                        if (el.otherValue != ""){
                            multanswerTemp.push(el.otherValue);
                        }
                        el.multanswer = multanswerTemp;
                        console.log(el.multanswer)
                    }
                });
                let answers = {
                    questionnaireTitle: this.title,
                    body: answerObj,
                };
                let postObj = {
                    answers: JSON.stringify(answers),
                    user: this.user,
                };
                // post("/api/PostquestionnaireAnswerss", postObj).then((res) => {
                post("/api/saveQuestionResult", postObj).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: "提交成功！",
                            type: "success",
                        });
                        this.isSubmit = true;
                    }
                });
            },
            //多选题重新排序
            sortcheckedCities(originArr, choosedArr) {
                var arr = [];
                for (let i = 0; i < choosedArr.length; i++) {
                    var arrMap = {};
                    for (let j = 0; j < originArr.length; j++) {
                        if (choosedArr[i] == originArr[j].label){
                            arrMap.name = choosedArr[i];
                            arrMap.index = j;
                            arr.push(arrMap);
                        }
                    }
                }
                var arrTemp = arr.sort(
                    function(a, b)
                    {
                        if(a.index < b.index) return -1;
                        if(a.index > b.index) return 1;
                        return 0;
                    }
                );
                var arrNew = [];
                for (let i = 0; i < arrTemp.length; i++) {
                    arrNew.push(arrTemp[i].name)
                }
                return arrNew;
            },
            getQuestionnaireQuestionss() {
                if (this.questionnaireType == null) {
                    this.$message({
                        message: "暂无问卷",
                        type: "warning",
                    });
                    return;
                }
                // get("/api/getQuestion?type=" + this.questionnaireType).then((res) => {
                get("/api/getQuestionByGroupId?groupId=" + this.questionnaireType).then((res) => {
                    if (res.status == 200) {
                        let obj = JSON.parse(res.data.data?.question);
                        this.title = obj?.questionnaireTitle;
                        let list = obj?.body;
                        list.map((el) => {
                            // 单选多选
                            if (el.type == 2 || el.type == 3) {
                                el.options.map((item) => {
                                    // 其他
                                    if (item.type == 1) {
                                        this.$set(el, "otherValue", "");
                                    }
                                });
                            }
                            if (el.type == 3) {
                                this.$set(el, "multanswer", []);
                                this.$set(el, "answer", []);
                            } else if (el.type == 1) {
                                let count = this.getCharCount2(el.title, "<underline/>");
                                this.$set(el, "answer", []);
                                for (let index = 0; index < count; index++) {
                                    el.answer.push("");
                                }
                                let fillList = el.title.split(/(<underline\/>)/g); // let "A、B、C".split(/(、)/g) == ["1", "、", "2", "、", "3"]
                                let typeList = [];
                                let i = 0;
                                fillList.map((el) => {
                                    if (el === "<underline/>") {
                                        typeList.push({
                                            type: 1,
                                            content: "<underline/>",
                                            index: i,
                                        });
                                        i++;
                                    } else {
                                        typeList.push({
                                            type: 2,
                                            content: el,
                                        });
                                    }
                                });
                                this.$set(el, "typeList", typeList);
                            } else {
                                this.$set(el, "answer", "");
                            }
                        });
                        this.pullList = list;
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: "error",
                        });
                        this.isSubmit = true;
                    }
                });
            },
            getCharCount2(str, char) {
                let regex = new RegExp(char, "g");
                let result = str.match(regex);
                let count = !result ? 0 : result.length;
                return count;
            },
            hglogout() {
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                this.user = null;
                this.$message({
                    message: "退出登录成功",
                    type: "success",
                });
            },
            doLogin() {
                if (!this.username) {
                    this.$message({
                        message: "请输入用户账号",
                        type: "warning",
                    });
                    return;
                }
                if (!this.password) {
                    this.$message({
                        message: "请输入密码",
                        type: "warning",
                    });
                    return;
                }
                get(
                    "/api/login?user=" + this.username + "&password=" + this.password
                ).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: "登录成功",
                            type: "success",
                        });
                        let tem = res.data.data;
                        this.user = tem.user;
                        localStorage.token = tem.token;
                        localStorage.user = tem.user;
                        this.handleClose();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "warning",
                        });
                    }
                });
            },
            toRegister() {
                this.innerVisible = true;
            },
            handleClose() {
                (this.username = ""), (this.password = "");
                this.dialogVisible = false;
            },
            handleCloseInner() {
                this.ruleForm = {
                    user: "",
                    password: "",
                    enterpriseName: "",
                };
                this.innerVisible = false;
            },
            registerConfirm() {
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        post("/api/logins", this.ruleForm).then((res) => {
                            if (res.status == 200) {
                                this.$message({
                                    message: "注册成功",
                                    type: "success",
                                });
                                this.innerVisible = false;
                            } else if (res.status == 201) {
                                this.$message({
                                    message: res.data.other,
                                    type: "warning",
                                });
                            }
                        });
                    } else {
                        this.$message({
                            message: "表单验证失败",
                            type: "warning",
                        });
                        return false;
                    }
                });
            },
            // 获取其他选项的文字
            getOtherText(opt) {
                let text = "";
                opt.map((el) => {
                    if (el.type == 1) text = el.label;
                });
                return text;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .exposure-rating {
        position: relative;
        height: 100vh;
        background: #f0f0f0;
        display: flex;
        justify-content: center;
    }

    .dw_body_content {
        margin-top: 60px;
        overflow: auto;
        background: #fff;
        border: none;
        position: relative;
        z-index: 50;
        width: 900px;
        // margin: 0 auto;
        padding: 0;
    }

    .dwSurveyHeader {
        display: flex;
        justify-content: center;
        margin: 20px 0 35px;
        text-align: center;
        outline-style: none;
        font-family: 微软雅黑;
        font-size: 21px;
        letter-spacing: 1px;
        cursor: text;
        padding: 5px;
        overflow: auto;
        color: #000;
    }

    .dwSurveyQuContent {
        min-height: 300px;
        padding: 5px 30px 20px;
        font-size: 16px;
        color: rgb(51, 51, 51);
        font-family: 微软雅黑;
        line-height: 1.4em;
        margin-bottom: 0px;
        overflow: auto;
    }

    .surveyQuItemContent {
        padding: 0;
        border: none;
        min-height: 50px;
        width: 100%;
    }

    .que-input {
        width: 80%;
    }

    .under-input {
      width: 200px;
    }
    ::v-deep .el-radio {
        display: block;
        margin: 10px 0;
    }

    .login-box {
        height: 50px;
        display: flex;
        align-items: center;
        margin: 0 50px;
    }

    .action-area {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        height: 60px;
    }
</style>

<style lang="less" scoped>
    /*自定义el-input框样式*/
    /deep/ .under-input-new .el-input__inner {
        border-radius: 0px;
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 1.5px;
        border-bottom-color: rgb(51, 51, 51);
    }
</style>
